import React from "react"
import * as styles from "./blogcard.module.css"
import { Link } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"

export default function BlogCard({ title, post, featuredImage }) {
  return (
    <Link to={post.uri} itemProp="url">
      <div className={styles.blogCard}>
        <article
          className="post-list-item"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            {featuredImage?.data && (
              <div className={styles.featuredImage}>
                <GatsbyImage
                  image={featuredImage.data}
                  alt={featuredImage.alt}
                  fullWidth="FULL_WIDTH"
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            )}
            <h2>
              <Link to={post.uri} itemProp="url">
                <span itemProp="headline">{parse(title)}</span>
              </Link>
            </h2>
            <div className={styles.date}>
              <small>{post.date}</small>
            </div>
          </header>
          <section itemProp="description">{parse(post.excerpt)}</section>
        </article>
      </div>
    </Link>
  )
}
